export const es = {
  yes: 'Sí',
  no: 'No',
  of: 'de',
  clear: 'Limpiar',
  confirm: 'Confirmar',
  apply: 'Aplicar',
  copy: 'Copiar',
  copied: 'Copiado',
  saveChanges: 'Guardar cambios',
  ok: 'Aceptar',
  cancel: 'Cancelar',
  noData: 'No hay datos',
  download: 'Descargar',
  key: 'Clave',
  value: 'Valor',
  noAccess: 'No acceso',
  noPermission: 'No tienes permiso para acceder a esta sección',
  edit: 'Editar',
  delete: 'Eliminar',
  search: 'Buscar',
  add: 'Agregar',
  added: 'Añadido',
  from: 'De',
  to: 'A',
  skipWeekends: 'Omitir fines de semana',
  next: 'Siguiente',
  back: 'Atrás',

  dayOfWeek: {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
  },

  datePicker: {
    dateFrom: 'Fecha desde',
    dateTo: 'Fecha hasta',
  },

  home: {
    welcome: 'Hola,',
    chooseScenario: 'Selecciona escenario, para comenzar',
  },

  users: {
    title: 'Usuarios',
    link: 'Gestión de usuarios',
    options: 'Opciones',
    addUser: 'Crear un nuevo usuario',
    addExistingScenarioUser: 'Añadir un usuario existente',
    addExistingUserToScenario: 'Agregar un usuario existente al escenario',
    chooseRole: 'Elegir un rol',
    user: {
      userData: 'Datos del usuario',
      scenarioGroups: 'Grupos de escenarios',
      profile: 'Perfil de',
      active: 'Activo',
      inactive: 'Inactivo',
      id: 'ID',
      firstName: 'Nombre',
      lastName: 'Apellido',
      username: 'Nombre de usuario',
      role: 'Rol',
      email: 'Correo electrónico',
      enabled: 'Habilitado',
      emailVerified: 'Verificado',
      group: 'Grupo asignado en el escenario',
      scenario: 'Escenario',
      scenarios: {
        showGroups: 'Haz clic en el escenario para mostrar los grupos',
      },
      clickToChangeStatus: 'Haz clic para cambiar el estado',
    },
    roles: {
      user: 'Usuario',
      admin: 'Administrador',
    },
    noResults: 'Sin resultados',
    error: 'Hubo un error al obtener los datos',
    deleteUserTitle: 'Eliminar usuario',
    deleteUserMessage: '¿Estás seguro de que quieres eliminar este usuario? Esta acción no se puede deshacer.',
    deleteUserFromGroupTitle: 'Eliminar usuario del escenario',
    deleteUserFromGroupMessage: '¿Estás seguro de que quieres eliminar este usuario del escenario dado?',
    details: 'Detalles',
    deleteFromGroup: 'Eliminar del grupo',
    edit: 'Editar',
    selectScenario: 'Seleccionar escenario',
    allUsers: 'Todos los usuarios',
    snackbar: {
      successfullyCreated: 'Usuario creado con éxito',
      successfullyUpdated: 'Datos del usuario actualizados con éxito',
      successfullyDeleted: 'Usuario eliminado con éxito',
      successfullyDeletedFromGroup: 'Usuario eliminado con éxito del escenario',
      successfullyEnabledDisabled: 'Estado del usuario cambiado con éxito',
      successfullyEditedGroup: 'Asignación de grupo de usuario editada con éxito',
      failedToCreate: 'Error al crear el usuario',
      failedToUpdate: 'Error al actualizar el usuario',
      failedToDelete: 'Error al eliminar el usuario',
      failedToDeleteFromGroup: 'Error al eliminar el usuario del escenario',
      failedToEnableDisable: 'Error al cambiar el estado del usuario',
      failedToEditGroup: 'No se pudo cambiar el grupo del usuario',
      resetPasswordRequestSuccess: 'Solicitud enviada con éxito',
      resetPasswordRequestError: 'Error al enviar la solicitud de restablecimiento',
    },
    resetPassword: 'Restablecer la contraseña',
  },

  dashboard: {
    heading: 'Tablero',
    show: 'Mostrar',
    today: 'Últimas 24 horas',
    lastWeek: 'La semana pasada',
    last7Days: 'Últimos 7 días',
    lastMonth: 'El mes pasado',
    currentMonth: 'Mes actual',
  },

  reports: {
    heading: 'Informes',
    selectReport: 'Seleccionar informe',
    selectBtn: 'Seleccionar',
    chooseFileFormat: 'Elegir formato de archivo',
    downloadBtn: 'Descargar informe',
    defaultTitle: '¡Atención!',
    defaultDateRange: 'No se seleccionó un rango de fechas. El informe se generará para el día actual.',
    changedDateRange:
      'El rango de fechas seleccionado es mayor a {{maxDateRange}} días. El archivo contendrá el informe solo de {{maxDateRange}} días.',
    details: 'Detalles',
    noResults: 'No hay resultados',
    noReports: 'No hay informes disponibles',
  },

  history: {
    heading: 'Historial de llamadas',
    downloadBtn: 'Descargar registros',
    connectionId: 'Id de conexión',
    direction: 'Dirección',
    directionValues: {
      InboundCall: 'Llamada entrante',
      OutboundCall: 'Llamada saliente',
    },
    numberFrom: 'Número de',
    numberTo: 'Número a',
    status: 'Estado',
    statusValues: {
      ReceivedCall: 'Llamada recibida',
      MissedCall: 'Llamada perdida',
      Unspecified: 'No especificado',
    },
    reasonForNoResponse: 'Razón por la que no se recibió respuesta',
    reasonForNoResponseValues: {
      Machine: 'Máquina',
      Missed: 'Perdido',
      Unknown: 'Desconocido',
    },
    duration: 'Duración',
    recording: 'Grabación',
    date: 'Fecha',
    hour: 'Hora',
    recordingModal: {
      description: 'Escucha la grabación de la llamada',
      downloadRecording: 'Descargar grabación',
      noData: 'La grabación de la llamada no está disponible',
    },
    transcription: {
      heading: 'Transcripción de la grabación de la llamada',
      noData: 'La transcripción de la grabación de la llamada no está disponible',
      botsaid: 'Bot',
      humansaid: 'Persona',
      noAnswer: 'Sin respuesta',
      closeBtn: 'Cerrar',
      startedLabel: 'Comenzado: ',
      endedLabel: 'Terminado: ',
    },
    call: {
      details: 'Detalles de la llamada',
      noDetails: 'No se encontraron detalles de la llamada',
    },
    defaultTitle: '¡Atención!',
    defaultDateRange: 'No se seleccionó un rango de fechas. El historial se generará para el día actual.',
    changedDateRange:
      'El rango de fechas seleccionado es mayor que {{maxDateRange}} días. El archivo contendrá el historial solo de {{maxDateRange}} días.',
    noResults: 'No hay resultados',
  },

  callsSchedule: {
    daysOfCalls: 'fechas de llamada',
    heading: 'Programación de llamadas',
    bar: {
      selectCollection: 'Seleccionar colecciones',
      selectOptions: {
        all: 'Todos',
        planned: 'Planificado',
        inProgress: 'En progreso',
        done: 'Hecho',
        canceled: 'Cancelado',
      },
      showBtn: 'Mostrar',
      addBtn: 'Agregar colección',
    },
    list: {
      selectCollections: 'Seleccionar colecciones para mostrar',
      noCollections: 'No se encontraron colecciones que coincidan',
    },
    collection: {
      status: 'Estado',
      nextCallDate: 'Próxima fecha de llamada',
      calledRecords: 'Número de registros',
      start: 'Comenzar',
      stop: 'Parar',
      calling: 'procesando',
      immediateCallings: {
        title: '¿Estás seguro de que quieres llamar de inmediato a todos los registros de este lote?',
        information:
          'La confirmación hará una llamada telefónica a {{immediateCallingsCount}} de' +
          '{{recordsCount}} números para esta colección. ¡Las llamadas se harán al instante!',
        description:
          'Si se logra el éxito en la llamada, resultará en la ejecución exitosa del escenario y no se harán más llamadas.\n' +
          'Si no se logra el éxito, la colección trabajará de acuerdo a su configuración estándar',
        cancel: 'Cancelar',
        confirm: 'Sí, llamar',
        tooltip: 'Llamar ahora',
      },
      collectionHistory: {
        title: 'Historial del lote',
        tooltip: 'Historial del lote',
        unknown: 'Estado desconocido',
        unknownDescription: '¡informa el problema al soporte de HelloBot!',
        drafted: 'Borrador',
        draftedDescription: 'listo para guardar',
        saved: 'Guardado',
        savedDescription: 'listo para empezar',
        started: 'Proceso iniciado',
        startedDescription: 'comenzar a procesar todos los registros del lote',
        processing: 'En progreso',
        processingDescription: 'registros trabajando, proceso en progreso',
        completed: 'Hecho',
        completedDescription: '¡éxito! Todos los registros terminaron el trabajo',
        canceled: 'Cancelado',
        canceledDescription: 'lote cancelado por el usuario',
        faulted: '¡Error!',
        faultedDescription: '¡ocurrió un error inesperado! ¡Informe el problema al soporte de HelloBot!',
      },
      recordHistory: {
        buttonTitle: 'Historial',
        title: 'Historial de registro - {{phoneNumber}}',
        tooltip: 'Historial de registro',
        unknown: 'Estado desconocido',
        unknownDescription: '¡informa el problema al soporte de HelloBot!',
        drafted: 'Borrador',
        draftedDescription: 'listo para guardar',
        saved: 'Guardado',
        savedDescription: 'listo para empezar',
        processingrequested: 'Proceso iniciado',
        processingrequestedDescription: 'comenzar a procesar el registro',
        processing: 'En progreso',
        processingDescription: 'registro trabajando, proceso en progreso',
        incall: 'Llamada telefónica',
        incallDescription: 'registro en llamada telefónica',
        waiting: 'En progreso',
        waitingDescription: 'registro todavía trabajando, proceso en progreso',
        completed: 'Hecho',
        completedDescription: '¡éxito! Todos los registros terminaron el trabajo',
        cancelingrequested: 'Cancelación solicitada',
        cancelingrequestedDescription: 'cancelación del registro solicitada por el usuario',
        canceled: 'Cancelado',
        canceledDescription: 'registro cancelado',
        faulted: '¡Error!',
        faultedDescription: '¡ocurrió un error inesperado! ¡Informe el problema al soporte de HelloBot!',
      },
      editOptions: {
        editOptionsTooltip: 'Opciones',
        changeName: 'Cambiar nombre',
        cancelCollection: 'Cancelar colección',
        duplicateCollection: 'Duplicar colección',
      },
      cancelCollectionModal: {
        title: '¿Estás seguro de que quieres cancelar la colección?',
        info: 'La colección no será llamada',
      },
      editCollectionModal: {
        title: 'Cambiar el nombre de la colección',
      },
      duplicateCollectionModal: {
        title: '¿Estás seguro de que quieres duplicar la colección?',
        info: 'Crear un duplicado eliminará la copia de trabajo guardada de la colección',
      },
      startCallingModal: {
        title: '¿Estás seguro de que quieres comenzar a llamar?',
      },
      import: {
        title: 'Hubo un problema al importar el archivo',
        numberErrors: 'Errores en el número de teléfono',
        id: 'ID',
        column: 'Columna',
        index: 'Índice',
        error: 'Error',
        errorDictionary: {
          noValues: 'Sin valor',
          incorrectType: 'Tipo incorrecto',
          incorrectValue: 'Valor incorrecto',
        },
        additionalParametersErrors: 'Error en parámetros adicionales',
      },
    },
    collectionStatus: {
      unknown: 'Desconocido',
      drafted: 'Borrador - listo para guardar',
      saved: 'Guardado - listo para comenzar',
      started: 'Durante el inicio..',
      processing: 'En progreso',
      completed: 'Hecho',
      canceled: 'Cancelado',
      faulted: '¡Error!',
    },
    draftCollection: {
      schedule: 'horario de llamadas',
      successSavedAndStartAlert: 'La colección {{batchName}} se ha guardado e iniciado.',
      addNewDateRangeError: 'No puedes agregar un rango de fechas que incluya la hora actual',
      delay: 'Retraso ',
      repeat: ' Repetir',
      addDateRange: 'Agregar rango de fechas',
      settings: 'Configuraciones de parámetros',
      addNewDateRange: 'Agregar un nuevo rango de llamadas telefónicas',
      addNewDateRangeDescription: 'Establecer el rango de días y horas durante los cuales se deben hacer las llamadas',
      addCollection: 'Agregar colección',
      collectionName: 'Nombre de la colección',
      addRecords: 'Agregar registros',
      importFile: 'Importar archivo',
      downloadTemplate: 'Descargar plantilla',
      addRecordsManually: 'Agregar registros manualmente',
      createCollection: 'Guardar colección',
      startCollection: 'Comenzar colección',
      clearCollection: 'Limpiar colección',
      numberOfAddedRecords: 'Número de registros agregados',
      phoneNumber: 'Número de teléfono',
      options: 'Opciones',
      errors: {
        saveCollectionError: 'Se produjo un error al seleccionar las opciones de guardar.',
        requiredParameterField: 'Campo de parámetro requerido',
        cannotRemoveLastDateRange: 'La colección debe tener al menos un rango de fechas.',
        enterCollectionName: 'Ingresa un nombre para la colección',
        maximumRecordExceeded: 'Límite de registros superado (250)',
        wrongFileFormat: 'Formato de archivo incorrecto',
        fileReadingError: 'Error al leer el archivo',
      },
    },
    records: {
      noRecords: 'No hay registros para mostrar',
      phone: 'Número de teléfono',
      registration: 'Número de registro',
      name: 'Nombre de la empresa',
      visitDate: 'Fecha de visita',
      status: 'Estado',
      preview: 'Vista previa',
      edit: 'Editar',
      cancel: 'Cancelar',
      options: 'Opciones',
      parameters: 'Parámetros',
      parametersTooltip: 'Parámetros del registro',
      cancelSession: {
        title: '¡Hey! ¿Estás seguro de que quieres cancelar la sesión del registro {{phoneNumber}}?',
        description: 'La confirmación cancelará la sesión del registro. ¡La conexión se detendrá de inmediato!',
        cancel: 'Cancelar',
        confirm: 'Sí, cancelar',
      },
      immediateCalling: {
        title: '¡Hey! ¿Estás seguro de que quieres llamar inmediatamente a {{phoneNumber}}?',
        description:
          'La confirmación hará una llamada telefónica. ¡La conexión se realizará de inmediato! ' +
          'Si se logra el éxito en la llamada, resultará en la ejecución exitosa del escenario y no se harán ' +
          'más llamadas. Si no se logra el éxito, el registro trabajará con su configuración estándar.',
        cancel: 'Cancelar',
        confirm: 'Sí, llamar',
        tooltip: 'Llamar ahora',
        callNow: 'Llamar ahora',
        cancelSession: 'Cancelar sesión',
      },
    },
    record: {
      details: 'Parámetros del registro',
      phoneNumber: 'Número de teléfono',
      addRecordToCollection: 'Agregar registro a la colección',
      addNextRecord: 'Agregar siguiente registro',
      addRecordAndGoToList: 'Agregar registro e ir a la lista',
    },
    recordStatus: {
      unknown: 'Desconocido',
      drafted: 'Borrador - listo para guardar',
      saved: 'Guardado - listo para comenzar',
      processingrequested: 'Procesamiento programado',
      processing: 'En proceso',
      incall: 'En llamada telefónica',
      waiting: 'Esperando la siguiente llamada',
      completed: 'Hecho',
      cancelingrequested: 'Cancelación programada',
      canceled: 'Cancelado',
      faulted: '¡Error!',
    },
  },

  call: {
    heading: 'Llamada inmediata',
    add: 'Agregar llamada',
    call: 'Llamar',
    pastCalls: 'Historial de llamadas',
    callId: 'Id',
    callDate: 'Fecha',
    callStatus: 'Estado',
    phoneNumber: 'Número de teléfono',
    details: 'Detalles',
  },

  scenarioConfigurator: {
    heading: 'Configurador de escenario',
    collectionType: {
      editable: 'Editable',
      editableValues: 'Valores editables',
      static: 'Solo lectura',
      hidden: 'Oculto',
    },
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Eliminar',
    noValues: 'No se encontraron valores',
    noCollections: 'Sin colección para el guion',
    createFeedback: {
      success: 'Nuevo elemento añadido con éxito',
      error: 'No se pudo añadir el nuevo elemento',
    },
    editFeedback: {
      success: 'Escenario modificado con éxito',
      error: 'No se pudo modificar la configuración del escenario',
    },
    deleteFeedback: {
      success: 'Elemento eliminado con éxito',
      error: 'No se pudo eliminar el elemento',
    },
    newDocument: 'Nuevo documento',
    addNewDocument: 'Añadir nuevo documento',
  },

  scenarioUsers: {
    heading: 'Usuarios del escenario',
    createNewScenarioUser: 'Crear un nuevo usuario de escenario',
    noScenarioGroupsError:
      'Escenario no configurado. Para añadir un usuario, se deben definir los roles del escenario.',
    editUser: 'Editar usuario',
  },

  navbar: {
    change: 'Cambiar',
    logout: 'Cerrar sesión',
  },

  footer: {
    contact: 'Contacto',
  },

  selectLang: 'Seleccionar idioma',

  validation: {
    required: 'Campo requerido',
    invalidEmail: 'Correo electrónico inválido. Ejemplo: ejemplo@ejemplo.com',
    minLength: 'No se ha cumplido el número mínimo de caracteres',
  },
  errorCode: {
    start_stop_range_already_exists: 'Ya existe un rango de inicio y fin',
    entity_not_found: 'No se encontró el recurso',
    columns_type_mismatch: 'Error de tipo de columna',
    report_id_and_mapping_mismatch: 'Error de mapeo',
    email_notification_failure: 'Error al enviar notificación',
    report_view_previous_version_not_found: 'No se encontró la versión anterior del informe',
    object_already_exists: 'El objeto ya existe',
    object_not_found: 'No se encontró el objeto',
    object_access_denied: 'Acceso denegado por falta de permisos',
    report_view_already_exists: 'La vista del informe ya existe',
    column_name_mismatch: 'Error en el nombre de la columna',
    call_orchestrator_not_found: 'No se encontró el orquestador de llamadas',
    call_already_exists: 'La llamada ya existe',
    call_orchestrator_already_exists: 'El orquestador de llamadas ya existe',
    call_orchestrator_has_active_calls: 'El orquestador de llamadas tiene llamadas activas',
    max_calls_too_low: 'Se superó el número máximo de llamadas',
    min_inbound_calls_too_low: 'Se superó el número mínimo de llamadas entrantes',
    max__redirect_calls_too_low: 'Se superó el número máximo de redirecciones',
    redirect_inbound_calls_sum_max_mismatch: 'Error en la suma máxima de llamadas entrantes y redirecciones',
    call_date_times_missing_dates: 'Falta la fecha de la llamada',
    value_null_or_empty: 'El valor no puede estar vacío',
    created_by_value_mismatch: 'Error en el valor del creador',
    daily_calls_plan_missing_days: 'Faltan días en el plan de llamadas diarias',
    delay_out_of_range: 'Error de rango de retraso',
    guid_null_or_empty: 'El GUID no puede estar vacío',
    invalid_time_zone_id: 'Identificador de zona horaria inválido',
    phone_number_column_null_or_empty: 'La columna del número de teléfono no puede estar vacía',
    phone_number_null_or_empty: 'El número de teléfono no puede estar vacío',
    record_status_mismatch: 'Error en el estado del registro',
    record_parameters_null_or_empty: 'Los parámetros del registro no pueden estar vacíos',
    repeat_value_out_of_range: 'Error en el rango de valor de repetición',
    start_stop_date_mismatch: 'Error en el rango de fecha de inicio y fin',
    start_stop_date_close_range_mismatch: 'Error en el rango de fecha de inicio y fin cercano',
    records_to_start_not_found: 'No se encontraron registros para iniciar',
    batch_cancellation_status_mismatch: 'Error en el estado de cancelación del lote',
    batch_immediate_start_status_mismatch: 'Error en el estado de inicio inmediato del lote',
    batch_for_record_not_found: 'No se encontró lote para el registro',
    parameter_parsing_failure: 'Error al analizar parámetros',
    default_parameters_null_or_empty: 'Los parámetros predeterminados no pueden estar vacíos',
    draft_batch_already_exists: 'El borrador del lote ya existe',
    draft_batch_not_found: 'No se encontró el borrador del lote',
    immediate_call_not_supported: 'La llamada inmediata no está soportada',
    record_immediate_calling: 'El registro está en llamada inmediata',
    records_number_out_of_range: 'Error en el rango del número de registros',
    start_time_out_of_range: 'Error en el rango de hora de inicio',
    file_records_not_found: 'No se encontraron registros en el archivo',
    batch_update_failure: 'Error en la actualización del lote',
    file_upload_failure: 'Error en la carga del archivo',
    validation_error: 'Error de validación',
  },
};
