import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';
import { Box, Grid, TextField } from '@mui/material';
import { MAX_DATE_RANGE } from '../../../../common/constants';
import { useModal } from '../../../../common/hooks';
import { downloadFile } from '../../../../common/utils';
import { ConfirmModal } from '../../../../components/confirm-modal';
import PaginationComponent from '../../../../components/pagination-component/pagination-component';
import { useCallHistory, useCallHistoryFile } from '../../hooks';
import { HistoryContext } from '../../provider/history-context.provider';
import { ExportToFile } from '../../../../components/export-to-file';
import { FileType } from '../../../../common/enums/file-types.enums';

export const HistoryBar: React.FC = () => {
  const { t } = useTranslation();
  const { pageNumber, dateRange, search } = useContext(HistoryContext);
  const [fileType, setFileType] = useState(FileType.CSV);
  const callHistoryFile = useCallHistoryFile(fileType);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const confirmModal = useModal();
  const historyCalls = useCallHistory();
  const [totalCount, setTotalCount] = useState(historyCalls.data?.totalCount || 0);
  const [searchValue, setSearchValue] = useState<string>(search.value);

  const handleDownloadHistory = async () => {
    const response = await callHistoryFile.refetch();
    downloadFile(response.data as unknown as Blob, `history.${fileType}`);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      search.set(searchValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const handleClickOnDownloadHistory = () => {
    if (dateRange.value.from && dateRange.value.to) {
      if (differenceInDays(dateRange.value.to, dateRange.value.from) > MAX_DATE_RANGE) {
        setModalTitle(t('history.defaultTitle'));
        setModalMessage(t('history.changedDateRange', { maxDateRange: MAX_DATE_RANGE }));
        confirmModal.open();
      } else {
        handleDownloadHistory();
      }
    } else {
      setModalTitle(t('history.defaultTitle'));
      setModalMessage(t('history.defaultDateRange'));
      confirmModal.open();
    }
  };

  useEffect(() => {
    if (typeof historyCalls.data?.totalCount !== 'number') return;
    setTotalCount(historyCalls.data?.totalCount || 0);
  }, [historyCalls.data?.totalCount]);

  return (
    <Box
      sx={{
        width: '100%',
        paddingTop: {
          sm: 1.5,
        },
        paddingBottom: {
          sm: 1.5,
        },
      }}
    >
      <Grid container spacing={2} justifyContent={'flex-end'}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: {
              sm: 'flex-end',
            },
            flexWrap: {
              xs: 'wrap-reverse',
              sm: 'nowrap',
            },
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
        >
          <TextField
            label={t('history.searchPlaceholder')}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              width: '100%',
              maxWidth: 400,
            }}
          />
          {!!totalCount && (
            <PaginationComponent
              count={totalCount || 10}
              page={pageNumber.value - 1 || 0}
              pageNumber={pageNumber}
              rowsPerPage={10}
            />
          )}
          <ExportToFile
            text={t('reports.downloadBtn')}
            disabled={callHistoryFile.isLoading || historyCalls.isLoading || !historyCalls.data?.totalCount}
            handleExport={handleClickOnDownloadHistory}
            onSelectedFileChanged={(val) => setFileType(val)}
            selectedFile={fileType}
          ></ExportToFile>
        </Grid>
      </Grid>
      <ConfirmModal
        modalOpen={confirmModal.isOpen}
        handleModalClose={confirmModal.close}
        handleConfirm={handleDownloadHistory}
        message={modalMessage}
        header={modalTitle}
      />
    </Box>
  );
};
