export const en = {
  yes: 'Yes',
  no: 'No',
  of: 'of',
  clear: 'Clear',
  confirm: 'Confirm',
  apply: 'Apply',
  copy: 'Copy',
  copied: 'Copied',
  saveChanges: 'Save changes',
  ok: 'OK',
  cancel: 'Cancel',
  noData: 'No data',
  download: 'Download',
  key: 'Key',
  value: 'Value',
  noAccess: 'No access',
  noPermission: 'You do not have permission to access this section',
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',
  add: 'Add',
  added: 'Added',
  from: 'From',
  to: 'To',
  skipWeekends: 'Skip weekends',
  next: 'Next',
  back: 'Back',

  dayOfWeek: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  },

  datePicker: {
    dateFrom: 'Date from',
    dateTo: 'Date to',
  },

  home: {
    welcome: 'Hi,',
    chooseScenario: 'Select scenario, to start',
  },

  users: {
    title: 'Users',
    link: 'Users management',
    options: 'Options',
    addUser: 'Create a new user',
    addExistingScenarioUser: 'Add an existing user',
    addExistingUserToScenario: 'Add an existing user to scenario',
    chooseRole: 'Choose a role',
    user: {
      userData: 'User data',
      scenarioGroups: 'Scenario groups',
      profile: 'Profile of',
      active: 'Active',
      inactive: 'Inactive',
      id: 'ID',
      firstName: 'Name',
      lastName: 'Surname',
      username: 'Username',
      role: 'Role',
      email: 'Email',
      enabled: 'Enabled',
      emailVerified: 'Verified',
      group: 'Assigned group in the scenario',
      scenario: 'Scenario',
      scenarios: {
        showGroups: 'Click on the scenario to display the groups',
      },
      clickToChangeStatus: 'Click to change status',
    },
    roles: {
      user: 'User',
      admin: 'Admin',
    },
    noResults: 'No results',
    error: 'There was an error getting the data',
    deleteUserTitle: 'Delete user',
    deleteUserMessage: 'Are you sure you want to delete this user? This action cannot be undone.',
    deleteUserFromGroupTitle: 'Remove user from scenario',
    deleteUserFromGroupMessage: 'Are you sure you want to remove this user from the given scenario?',
    details: 'Details',
    deleteFromGroup: 'Remove from group',
    edit: 'Edit',
    selectScenario: 'Select scenario',
    allUsers: 'All users',
    snackbar: {
      successfullyCreated: 'User created successfully',
      successfullyUpdated: 'User data updated successfully',
      successfullyDeleted: 'User deleted successfully',
      successfullyDeletedFromGroup: 'User successfully deleted from scenario',
      successfullyEnabledDisabled: 'User status changed successfully',
      successfullyEditedGroup: 'User group assignment successfully edited',
      failedToCreate: 'Failed to create the user',
      failedToUpdate: 'Failed to update the user',
      failedToDelete: 'Failed to delete the user',
      failedToDeleteFromGroup: 'Failed to delete the user from scenario',
      failedToEnableDisable: 'Failed to change the user status',
      failedToEditGroup: 'The user group could not be changed',
      resetPasswordRequestSuccess: 'Successfully sent request',
      resetPasswordRequestError: 'Failed to send the reset request',
    },
    resetPassword: 'Reset password',
  },

  dashboard: {
    heading: 'Dashboard',
    show: 'Show',
    today: 'Last 24h',
    lastWeek: 'Last week',
    last7Days: 'Last 7 days',
    lastMonth: 'Last month',
    currentMonth: 'Current Month',
  },

  reports: {
    heading: 'Reports',
    selectReport: 'Select report',
    selectBtn: 'Select',
    chooseFileFormat: 'Choose file format',
    downloadBtn: 'Download report',
    defaultTitle: 'Attention!',
    defaultDateRange: 'No date range selected. Report will be generated for current day.',
    changedDateRange:
      'Selected date range is bigger than {{maxDateRange}} days. File will contains report only from {{maxDateRange}} days.',
    details: 'Details',
    noResults: 'No results',
    noReports: 'No reports available',
  },

  history: {
    searchPlaceholder: 'Search',
    heading: 'Calls history',
    downloadBtn: 'Download records',
    connectionId: 'Connection Id',
    direction: 'Direction',
    directionValues: {
      InboundCall: 'Inbound call',
      OutboundCall: 'Outbound call',
    },
    numberFrom: 'Number from',
    numberTo: 'Number to',
    status: 'Status',
    statusValues: {
      ReceivedCall: 'Received call',
      MissedCall: 'Missed call',
      Unspecified: 'Unspecified',
    },
    reasonForNoResponse: 'Reason for no response',
    reasonForNoResponseValues: {
      Machine: 'Machine',
      Missed: 'Missed',
      Unknown: 'Unknown',
    },
    duration: 'Duration',
    recording: 'Recording',
    date: 'Date',
    hour: 'Hour',
    recordingModal: {
      description: 'Listen to the call recording',
      downloadRecording: 'Download recording',
      noData: 'Call recording is unavailable',
    },
    transcription: {
      heading: 'Call recording transcription',
      noData: 'Call recording transcription is unavailable',
      botsaid: 'Bot',
      humansaid: 'Person',
      noAnswer: 'No answer',
      closeBtn: 'Close',
      startedLabel: 'Started: ',
      endedLabel: 'Ended: ',
    },
    call: {
      details: 'Call details',
      noDetails: 'No call details found',
    },
    defaultTitle: 'Attention!',
    defaultDateRange: 'No date range selected. History will be generated for current day.',
    changedDateRange:
      'Selected date range is bigger than {{maxDateRange}} days. File will contains history only from {{maxDateRange}} days.',
    noResults: 'No results',
  },

  callsSchedule: {
    heading: 'Calls schedule',
    bar: {
      selectCollection: 'Select collections',
      selectOptions: {
        all: 'All',
        planned: 'Planned',
        inProgress: 'In progress',
        done: 'Done',
        canceled: 'Canceled',
      },
      showBtn: 'Show',
      addBtn: 'Add collection',
    },
    list: {
      selectCollections: 'Select collections to display',
      noCollections: 'No matching collections',
    },
    collection: {
      status: 'Status',
      nextCallDate: 'Next call date',
      calledRecords: 'Number of records',
      start: 'Start',
      stop: 'Stop',
      calling: 'processing',
      immediateCallings: {
        title: 'Are you sure you want to immediately call all records from this batch?',
        information:
          'Confirmation will make a phone call to {{immediateCallingsCount}} from' +
          '{{recordsCount}} numbers for this collection. Calls will be made instantly!',
        description:
          'Achieving success in the call will result in the successful execution of the scenario and no further calls.\n' +
          'If success is not achieved, the collection will work according to its standard configuration',
        cancel: 'Cancel',
        confirm: 'Yes, call',
        tooltip: 'Call now',
      },
      collectionHistory: {
        title: 'Batch history',
        tooltip: 'Batch history',
        unknown: 'Unknown status',
        unknownDescription: 'report the issue to HelloBot support!',
        drafted: 'Drafted',
        draftedDescription: 'ready to save',
        saved: 'Saved',
        savedDescription: 'ready to start',
        started: 'Started process',
        startedDescription: 'start processing all records from batch',
        processing: 'In progress',
        processingDescription: 'records working, process in progress',
        completed: 'Done',
        completedDescription: 'success! All records finished work',
        canceled: 'Canceled',
        canceledDescription: 'batch canceled by user',
        faulted: 'Error!',
        faultedDescription: 'an unexpected error occurred! Report the issue to HelloBot support!',
      },
      recordHistory: {
        buttonTitle: 'History',
        title: 'Record history - {{phoneNumber}}',
        tooltip: 'Record history',
        unknown: 'Unknown status',
        unknownDescription: 'report the issue to HelloBot support!',
        drafted: 'Drafted',
        draftedDescription: 'ready to save',
        saved: 'Saved',
        savedDescription: 'ready to start',
        processingrequested: 'Started process',
        processingrequestedDescription: 'start processing record',
        processing: 'In progress',
        processingDescription: 'record working, process in progress',
        incall: 'Phone call',
        incallDescription: 'record is in phone call',
        waiting: 'In progress',
        waitingDescription: 'record still working, process in progress',
        completed: 'Done',
        completedDescription: 'success! All records finished work',
        cancelingrequested: 'Cancel requested',
        cancelingrequestedDescription: 'record cancellation requested by user',
        canceled: 'Canceled',
        canceledDescription: 'record canceled',
        faulted: 'Error!',
        faultedDescription: 'an unexpected error occurred! Report the issue to HelloBot support!',
      },
      editOptions: {
        editOptionsTooltip: 'Options',
        changeName: 'Change name',
        cancelCollection: 'Cancel collection',
        duplicateCollection: 'Duplicate collection',
      },
      cancelCollectionModal: {
        title: 'Are you sure you want to cancel the collection?',
        info: 'The collection will not be called',
      },
      editCollectionModal: {
        title: 'Change collection name',
      },
      duplicateCollectionModal: {
        title: 'Are you sure you want to duplicate the collection?',
        info: 'Creating a duplicate will delete the saved working copy of the collection',
      },
      startCallingModal: {
        title: 'Are you sure you want to start calling?',
      },
      import: {
        title: 'There was a problem importing the file',
        numberErrors: 'Phone number errors',
        id: 'ID',
        column: 'Column',
        index: 'Index',
        error: 'Error',
        errorDictionary: {
          noValues: 'No value',
          incorrectType: 'Incorrect type',
          incorrectValue: 'Incorrect value',
        },
        additionalParametersErrors: 'Additional parameters error',
      },
    },
    collectionStatus: {
      unknown: 'Unknown',
      drafted: 'Drafted - ready to save',
      saved: 'Saved - ready to start',
      started: 'During starting..',
      processing: 'In progress',
      completed: 'Done',
      canceled: 'Canceled',
      faulted: 'Error!',
    },
    draftCollection: {
      schedule: 'calls schedule',
      successSavedAndStartAlert: 'The collection {{batchName}} has been saved and started.',
      addNewDateRangeError: 'You cannot add a date range that includes the current time',
      delay: 'Delay ',
      repeat: ' Repeat',
      addDateRange: 'Add date range',
      settings: 'Parameter settings',
      addNewDateRange: 'Add a new range of phone calls',
      addNewDateRangeDescription: 'Set the range of days and hours during which calls are to be made',
      addCollection: 'Add collection',
      collectionName: 'Collection name',
      addRecords: 'Add records',
      importFile: 'Import file',
      downloadTemplate: 'Download template',
      addRecordsManually: 'Add records manually',
      createCollection: 'Save collection',
      startCollection: 'Start collection',
      clearCollection: 'Clear collection',
      numberOfAddedRecords: 'Number of added records',
      phoneNumber: 'Phone number',
      options: 'Options',
      errors: {
        saveCollectionError: 'Error saving collection',
        requiredParameterField: 'Required parameter field',
        cannotRemoveLastDateRange: 'The collection must have at least one date range.',
        enterCollectionName: 'Enter a collection name',
        maximumRecordExceeded: 'Record limit exceeded (250).',
        wrongFileFormat: 'Wrong file format',
        fileReadingError: 'File reading error',
      },
    },
    records: {
      noRecords: 'No records to display',
      phone: 'Phone number',
      registration: 'Registration number',
      name: 'Company name',
      visitDate: 'Visit date',
      status: 'Status',
      preview: 'Preview',
      edit: 'Edit',
      cancel: 'Cancel',
      options: 'Options',
      parameters: 'Parameters',
      parametersTooltip: 'Record parameters',
      cancelSession: {
        title: 'Are you sure you want to cancel the session?',
        description: 'The record will not be called',
        cancel: 'cancel',
        confirm: 'Yes, cancel',
      },
      immediateCalling: {
        title: 'Hey! Are you sure you want to call {{phoneNumber}} immediately?',
        description:
          'Confirmation will make a phone call. The connection will be made immediately! ' +
          'Achieving success in the call will result in the successful execution of the scenario and no ' +
          'further calls. If success is not achieved, the record will work with its standard configuration.',
        cancel: 'Cancel',
        confirm: 'Yes, call',
        tooltip: 'Call now',
        callNow: 'Call now',
        cancelSession: 'Cancel session',
      },
    },
    record: {
      details: 'Record parameters',
      phoneNumber: 'Phone number',
      addRecordToCollection: 'Add record to collection',
      addNextRecord: 'Add next record',
      addRecordAndGoToList: 'Add record and go to the list',
    },
    recordStatus: {
      unknown: 'Unknown',
      drafted: 'Drafted - ready to save',
      saved: 'Saved - ready to start',
      processingrequested: 'Scheduled processing',
      processing: 'In process',
      incall: 'In phone call',
      waiting: 'Waiting for next call',
      completed: 'Done',
      cancelingrequested: 'Scheduled canceling',
      canceled: 'Canceled',
      faulted: 'Error!',
    },
  },

  call: {
    heading: 'Immediate call',
    add: 'Add call',
    call: 'Call',
    pastCalls: 'Call history',
    callId: 'Id',
    callDate: 'Date',
    callStatus: 'Status',
    phoneNumber: 'Phone number',
    details: 'Details',
  },

  scenarioConfigurator: {
    heading: 'Scenario configurator',
    collectionType: {
      editable: 'Editable',
      editableValues: 'Editable values',
      static: 'Read only',
      hidden: 'Hidden',
    },
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    noValues: 'No values found',
    noCollections: 'No collections for scenario',
    createFeedback: {
      success: 'New element added successfully',
      error: 'New element could not be added',
    },
    editFeedback: {
      success: 'Scenario modified successfully',
      error: 'The scenario configuration could not be modified',
    },
    deleteFeedback: {
      success: 'Element deleted successfully',
      error: 'Element could not be deleted',
    },
    newDocument: 'New document',
    addNewDocument: 'Add new document',
  },

  scenarioUsers: {
    heading: 'Scenario users',
    createNewScenarioUser: 'Create a new scenario user',
    noScenarioGroupsError: 'Unconfigured scenario. In order to add a user, scenario roles must be defined.',
    editUser: 'Edit user',
  },

  navbar: {
    change: 'Change',
    logout: 'Logout',
  },

  footer: {
    contact: 'Contact',
  },

  selectLang: 'Select language',

  validation: {
    required: 'Required field',
    invalidEmail: 'Invalid e-mail. Example: example@example.com',
    minLength: 'The minimum number of characters has not been met',
  },
  errorCode: {
    start_stop_range_already_exists: 'Selected date range already exists',
    entity_not_found: 'Resource not found',
    columns_type_mismatch: 'Column type error',
    report_id_and_mapping_mismatch: 'Mapping error',
    email_notification_failure: 'Error sending notification',
    report_view_previous_version_not_found: 'Previous version of the report not found',
    object_already_exists: 'The object already exists',
    object_not_found: 'Object not found',
    object_access_denied: 'Access denied due to lack of permissions',
    report_view_already_exists: 'The report view already exists',
    column_name_mismatch: 'Column name error',
    call_orchestrator_not_found: 'Call orchestrator not found',
    call_already_exists: 'The call already exists',
    call_orchestrator_already_exists: 'The call orchestrator already exists',
    call_orchestrator_has_active_calls: 'The call orchestrator has active calls',
    max_calls_too_low: 'Maximum number of calls exceeded',
    min_inbound_calls_too_low: 'Minimum number of inbound calls exceeded',
    max__redirect_calls_too_low: 'Maximum number of redirects exceeded',
    redirect_inbound_calls_sum_max_mismatch: 'Error in the maximum sum of inbound calls and redirects',
    call_date_times_missing_dates: 'Call date missing',
    value_null_or_empty: 'Value cannot be null or empty',
    created_by_value_mismatch: 'Creator value error',
    daily_calls_plan_missing_days: 'Missing days in the daily call plan',
    delay_out_of_range: 'Delay out of range',
    guid_null_or_empty: 'GUID cannot be null or empty',
    invalid_time_zone_id: 'Invalid time zone ID',
    phone_number_column_null_or_empty: 'Phone number column cannot be null or empty',
    phone_number_null_or_empty: 'Phone number cannot be null or empty',
    record_status_mismatch: 'Record status error',
    record_parameters_null_or_empty: 'Record parameters cannot be null or empty',
    repeat_value_out_of_range: 'Repeat value out of range',
    start_stop_date_mismatch: 'Start-stop date range error',
    start_stop_date_close_range_mismatch: 'Close range start-stop date error',
    records_to_start_not_found: 'Records to start not found',
    batch_cancellation_status_mismatch: 'Batch cancellation status error',
    batch_immediate_start_status_mismatch: 'Batch immediate start status error',
    batch_for_record_not_found: 'Batch for record not found',
    parameter_parsing_failure: 'Parameter parsing failure',
    default_parameters_null_or_empty: 'Default parameters cannot be null or empty',
    draft_batch_already_exists: 'Draft batch already exists',
    draft_batch_not_found: 'Draft batch not found',
    immediate_call_not_supported: 'Immediate call not supported',
    record_immediate_calling: 'Record in immediate calling',
    records_number_out_of_range: 'Number of records out of range',
    start_time_out_of_range: 'Start time out of range',
    file_records_not_found: 'File records not found',
    batch_update_failure: 'Batch update failure',
    file_upload_failure: 'File upload failure',
    validation_error: 'Validation error',
  },
};
